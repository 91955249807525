<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <!-- <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item label="城市">
            <el-input
              v-model="searchInfo.number"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="searchInfo.number"
              placeholder="请输入手机号"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item class="right-btns">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div> -->
    </div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <!-- 中间字段 -->
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in tableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span @click="getItemDetail(scope.row)" class="update-btn"
              >审核</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <div> -->
      <!-- <Pagination -->
      <!-- :total="dataTotal" -->
      <!-- :page="searchInfo.page" -->
      <!-- :limit="searchInfo.limit" -->
      <!-- @pagination="onChangePages" -->
      <!-- /> -->
      <!-- </div> -->
      <!-- 弹框组件 -->
      <!-- <DateEdit ref="edit" /> -->
    </div>
  </div>
</template>

<script>
// import DateEdit from "./components/DateEdit.vue";.
import { getComplaintList } from "../../api/common";

export default {
  components: {
    // Pagination,
    // DateEdit,
  },
  data() {
    return {
      // 诈骗时间
      datePickerVal: [],
      // 多选选中的数据
      selectList: [],
      // 诈骗类型数组
      fraudTypeList: [],
      // 查询数据
      searchInfo: {
        startTime: "",
        endTime: "",
        fraudType: "",
        // page: 1,
        // size: 10,
      },
      // 表格数据
      tableData: [],
      // 表格标题数组
      tableTitle: [
        { name: "车源ID", code: "sid", width: "150" },
        { name: "所在城市", code: "city", width: "100" },
        { name: "工单下发时间", code: "issueTime", width: "200" },
        { name: "车型", code: "model", width: "200" },
        { name: "申诉时间", code: "appealTime", width: "200" },
        // { name: "派发次数", code: "number", width: "200" },
      ],
      // 数据总数
      dataTotal: 100,
    };
  },
  mounted() {
    // 查询
    this.getTicketsList();
  },
  methods: {
    //获取工单管理列表信息
    getTicketsList() {
      getComplaintList().then((res) => {
        this.tableData = res.data.queryResult.list;
      });
    },
    // 修改诈骗时间日期框
    handleChangeDatePicker(e) {
      this.searchInfo.startTime = e[0];
      this.searchInfo.endTime = e[1];
    },
    // 多选
    handleSelectionChange(list) {
      this.selectList = list;
    },
    // 下载方法
    onDownload() {
      console.log(this.selectList);
    },
    // 重置
    onReset() {
      this.datePickerVal = [];
      this.searchInfo.startTime = "";
      this.searchInfo.endTime = "";
      this.searchInfo.fraudType = "";
      this.onSearch();
    },
    //跳转至审核界面
    getItemDetail(row) {
      console.log("row-------------" + JSON.stringify(row));
      localStorage.setItem("cid", row.cid);
      localStorage.setItem("sid", row.sid);
      this.$router.push({
        name: "HomeDetail",
        path: "/home/detail",
        params: { cid: row.cid, sid: row.sid, isShow: 1 },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
.update-btn {
  cursor: pointer;
  color: #409eff;
}
</style>
